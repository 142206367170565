@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 100%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }
}

// Size variations
.small div {
  width: 8px;
  height: 8px;
  margin: 0 3px;
}

.medium div {
  width: 12px;
  height: 12px;
  margin: 0 5px;
}

.large div {
  width: 18px;
  height: 18px;
  margin: 0 8px;
}

// Color variations
.default div {
  background-color: $brand-darkgrey;
}

.primary div {
  background-color: $brand-primary;
}

.white div {
  background-color: #ffffff;
}
