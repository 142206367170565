.redirectingContainer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  min-height: 200px;
}

.content {
  text-align: center;
  padding: 2rem;

  h1 {
    margin-bottom: 2rem;
    color: #333;
    font-size: 2rem;
  }
}
